import React, { ComponentType } from "react";

export enum CompassDirection {
  W,
  E,
  N,
  S
}

export const Arrow: ComponentType<{
  dir: CompassDirection;
}> = ({ dir }) => {
  let transform = undefined;
  switch (dir) {
    case CompassDirection.W:
      transform = "scale(-1,1)";
      break;
    case CompassDirection.E:
      break;
    case CompassDirection.N:
      transform = "rotate(-90)";
      break;
    case CompassDirection.S:
      transform = "rotate(90)";
      break;
    default:
      break;
  }
  return (
    <svg
      aria-hidden="true"
      className="w-5 h-5"
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
      transform={transform}
    >
      <path
        fillRule="evenodd"
        d="M10.293 3.293a1 1 0 011.414 0l6 6a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414L14.586 11H3a1 1 0 110-2h11.586l-4.293-4.293a1 1 0 010-1.414z"
        clipRule="evenodd"
      ></path>
    </svg>
  );
};

export const ThickArrow: ComponentType<{
  dir: CompassDirection;
  size?: number;
}> = ({ dir, size = 6 }) => {
  let transform = undefined;
  switch (dir) {
    case CompassDirection.W:
      transform = "scale(-1,1)";
      break;
    case CompassDirection.E:
      break;
    case CompassDirection.N:
      transform = "rotate(-90)";
      break;
    case CompassDirection.S:
      transform = "rotate(90)";
      break;
    default:
      break;
  }
  return (
    <svg
      style={{
        width: "1em",
        height: "1em",
        verticalAlign: "middle",
        fill: "currentColor",
        overflow: "hidden"
      }}
      className={`w-${size} h-${size}`}
      viewBox="0 0 1024 1024"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      transform={transform}
    >
      <path d="M1024 512L512 128v213.333333H0v341.333334h512v213.333333l512-384z" />
    </svg>
  );
};

export const Circle: ComponentType<{ size: number }> = ({ size }) => {
  return (
    <svg
      aria-hidden="true"
      className={`w-${size} h-${size}`}
      fill="currentColor"
      viewBox="0 0 20 20"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="10" r="10" />
    </svg>
  );
};

export const CircledArrow: ComponentType<{ size: number }> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={`w-${size} h-${size}`}
    >
      <path
        fillRule="evenodd"
        d="M10 18a8 8 0 100-16 8 8 0 000 16zM6.75 9.25a.75.75 0 000 1.5h4.59l-2.1 1.95a.75.75 0 001.02 1.1l3.5-3.25a.75.75 0 000-1.1l-3.5-3.25a.75.75 0 10-1.02 1.1l2.1 1.95H6.75z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const RubbishBin: ComponentType<{ size: number }> = ({ size }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={`w-${size} h-${size} bfg-`}
    >
      <path
        fillRule="evenodd"
        d="M8.75 1A2.75 2.75 0 006 3.75v.443c-.795.077-1.584.176-2.365.298a.75.75 0 10.23 1.482l.149-.022.841 10.518A2.75 2.75 0 007.596 19h4.807a2.75 2.75 0 002.742-2.53l.841-10.52.149.023a.75.75 0 00.23-1.482A41.03 41.03 0 0014 4.193V3.75A2.75 2.75 0 0011.25 1h-2.5zM10 4c.84 0 1.673.025 2.5.075V3.75c0-.69-.56-1.25-1.25-1.25h-2.5c-.69 0-1.25.56-1.25 1.25v.325C8.327 4.025 9.16 4 10 4zM8.58 7.72a.75.75 0 00-1.5.06l.3 7.5a.75.75 0 101.5-.06l-.3-7.5zm4.34.06a.75.75 0 10-1.5-.06l-.3 7.5a.75.75 0 101.5.06l.3-7.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const CollectLogo = () => {
  return (
    <svg
      width="35"
      height="35"
      viewBox="0 0 35 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.322 0H0V23.7288H10.678V35H35V11.2712H24.322V0ZM24.322 11.2712H10.678V23.7288H24.322V11.2712Z"
        fill="#00E5EF"
      />
    </svg>
  );
};

export const ArrowDownTray: ComponentType<{ fill: string }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`w-5 h-5 ${fill}`}
    >
      <path d="M10.75 2.75a.75.75 0 00-1.5 0v8.614L6.295 8.235a.75.75 0 10-1.09 1.03l4.25 4.5a.75.75 0 001.09 0l4.25-4.5a.75.75 0 00-1.09-1.03l-2.955 3.129V2.75z" />
      <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
    </svg>
  );
};

export const ArrowUpTray: ComponentType<{ fill: string }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`w-5 h-5 ${fill}`}
    >
      <path d="M9.25 13.25a.75.75 0 001.5 0V4.636l2.955 3.129a.75.75 0 001.09-1.03l-4.25-4.5a.75.75 0 00-1.09 0l-4.25 4.5a.75.75 0 101.09 1.03L9.25 4.636v8.614z" />
      <path d="M3.5 12.75a.75.75 0 00-1.5 0v2.5A2.75 2.75 0 004.75 18h10.5A2.75 2.75 0 0018 15.25v-2.5a.75.75 0 00-1.5 0v2.5c0 .69-.56 1.25-1.25 1.25H4.75c-.69 0-1.25-.56-1.25-1.25v-2.5z" />
    </svg>
  );
};

export const ChevronDown: ComponentType<{ fill: string }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`w-5 h-5 ${fill}`}
    >
      <path
        fillRule="evenodd"
        d="M5.23 7.21a.75.75 0 011.06.02L10 11.168l3.71-3.938a.75.75 0 111.08 1.04l-4.25 4.5a.75.75 0 01-1.08 0l-4.25-4.5a.75.75 0 01.02-1.06z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const MapPin: ComponentType<{ fill: string }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      className={`w-5 h-5 ${fill}`}
    >
      <path
        fillRule="evenodd"
        d="M9.69 18.933l.003.001C9.89 19.02 10 19 10 19s.11.02.308-.066l.002-.001.006-.003.018-.008a5.741 5.741 0 00.281-.14c.186-.096.446-.24.757-.433.62-.384 1.445-.966 2.274-1.765C15.302 14.988 17 12.493 17 9A7 7 0 103 9c0 3.492 1.698 5.988 3.355 7.584a13.731 13.731 0 002.273 1.765 11.842 11.842 0 00.976.544l.062.029.018.008.006.003zM10 11.25a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const CubeTransparent: ComponentType<{ fill: string }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={`w-5 h-5 ${fill}`}
    >
      <path
        fillRule="evenodd"
        d="M9.638 1.093a.75.75 0 01.724 0l2 1.104a.75.75 0 11-.724 1.313L10 2.607l-1.638.903a.75.75 0 11-.724-1.313l2-1.104zM5.403 4.287a.75.75 0 01-.295 1.019l-.805.444.805.444a.75.75 0 01-.724 1.314L3.5 7.02v.73a.75.75 0 01-1.5 0v-2a.75.75 0 01.388-.657l1.996-1.1a.75.75 0 011.019.294zm9.194 0a.75.75 0 011.02-.295l1.995 1.101A.75.75 0 0118 5.75v2a.75.75 0 01-1.5 0v-.73l-.884.488a.75.75 0 11-.724-1.314l.806-.444-.806-.444a.75.75 0 01-.295-1.02zM7.343 8.284a.75.75 0 011.02-.294L10 8.893l1.638-.903a.75.75 0 11.724 1.313l-1.612.89v1.557a.75.75 0 01-1.5 0v-1.557l-1.612-.89a.75.75 0 01-.295-1.019zM2.75 11.5a.75.75 0 01.75.75v1.557l1.608.887a.75.75 0 01-.724 1.314l-1.996-1.101A.75.75 0 012 14.25v-2a.75.75 0 01.75-.75zm14.5 0a.75.75 0 01.75.75v2a.75.75 0 01-.388.657l-1.996 1.1a.75.75 0 11-.724-1.313l1.608-.887V12.25a.75.75 0 01.75-.75zm-7.25 4a.75.75 0 01.75.75v.73l.888-.49a.75.75 0 01.724 1.313l-2 1.104a.75.75 0 01-.724 0l-2-1.104a.75.75 0 11.724-1.313l.888.49v-.73a.75.75 0 01.75-.75z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const Cube: ComponentType<{ fill: string }> = ({ fill }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={`w-5 h-5 ${fill}`}
    >
      <path
        fillRule="evenodd"
        d="M10.362 1.093a.75.75 0 00-.724 0L2.523 5.018 10 9.143l7.477-4.125-7.115-3.925zM18 6.443l-7.25 4v8.25l6.862-3.786A.75.75 0 0018 14.25V6.443zm-8.75 12.25v-8.25l-7.25-4v7.807a.75.75 0 00.388.657l6.862 3.786z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const UtilityMarkerIcon: ComponentType<{ className?: string }> = ({
  className
}) => {
  return (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="10" cy="7" r="6" stroke="#D1D5DB" strokeWidth="2" />
      <line
        x1="1"
        y1="7"
        x2="19"
        y2="7"
        stroke="#D1D5DB"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};

export const ArrowPath: ComponentType<{ className?: string }> = ({
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
    >
      <path
        fillRule="evenodd"
        d="M15.312 11.424a5.5 5.5 0 01-9.201 2.466l-.312-.311h2.433a.75.75 0 000-1.5H3.989a.75.75 0 00-.75.75v4.242a.75.75 0 001.5 0v-2.43l.31.31a7 7 0 0011.712-3.138.75.75 0 00-1.449-.39zm1.23-3.723a.75.75 0 00.219-.53V2.929a.75.75 0 00-1.5 0V5.36l-.31-.31A7 7 0 003.239 8.188a.75.75 0 101.448.389A5.5 5.5 0 0113.89 6.11l.311.31h-2.432a.75.75 0 000 1.5h4.243a.75.75 0 00.53-.219z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const PencilSquare: ComponentType<{ className?: string }> = ({
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20 20"
      fill="currentColor"
      className={className}
    >
      <path d="M5.433 13.917l1.262-3.155A4 4 0 017.58 9.42l6.92-6.918a2.121 2.121 0 013 3l-6.92 6.918c-.383.383-.84.685-1.343.886l-3.154 1.262a.5.5 0 01-.65-.65z" />
      <path d="M3.5 5.75c0-.69.56-1.25 1.25-1.25H10A.75.75 0 0010 3H4.75A2.75 2.75 0 002 5.75v9.5A2.75 2.75 0 004.75 18h9.5A2.75 2.75 0 0017 15.25V10a.75.75 0 00-1.5 0v5.25c0 .69-.56 1.25-1.25 1.25h-9.5c-.69 0-1.25-.56-1.25-1.25v-9.5z" />
    </svg>
  );
};

export const QuestionMark: ComponentType<{ className?: string }> = ({
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
      />
    </svg>
  );
};

export const CheckCircleOutline: ComponentType<{ className?: string }> = ({
  className
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 12.75L11.25 15 15 9.75M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
      />
    </svg>
  );
};

export const FaceFrown: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.182 16.318A4.486 4.486 0 0012.016 15a4.486 4.486 0 00-3.198 1.318M21 12a9 9 0 11-18 0 9 9 0 0118 0zM9.75 9.75c0 .414-.168.75-.375.75S9 10.164 9 9.75 9.168 9 9.375 9s.375.336.375.75zm-.375 0h.008v.015h-.008V9.75zm5.625 0c0 .414-.168.75-.375.75s-.375-.336-.375-.75.168-.75.375-.75.375.336.375.75zm-.375 0h.008v.015h-.008V9.75z"
      />
    </svg>
  );
};

export const MagnifyingGlass: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 21l-5.197-5.197m0 0A7.5 7.5 0 105.196 5.196a7.5 7.5 0 0010.607 10.607z"
      />
    </svg>
  );
};

export const MagnifyingGlassCircle: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      <path d="M8.25 10.875a2.625 2.625 0 115.25 0 2.625 2.625 0 01-5.25 0z" />
      <path
        fillRule="evenodd"
        d="M12 2.25c-5.385 0-9.75 4.365-9.75 9.75s4.365 9.75 9.75 9.75 9.75-4.365 9.75-9.75S17.385 2.25 12 2.25zm-1.125 4.5a4.125 4.125 0 102.338 7.524l2.007 2.006a.75.75 0 101.06-1.06l-2.006-2.007a4.125 4.125 0 00-3.399-6.463z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const AddPhotoIcon: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6"
}) => {
  return (
    <svg
      width="26"
      height="22"
      viewBox="0 0 26 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        d="M8.09815 12.1639C7.34408 12.1639 6.6209 12.4634 6.0877 12.9966C5.55449 13.5299 5.25494 14.253 5.25494 15.0071C5.25494 15.7612 5.55449 16.4843 6.0877 17.0175C6.6209 17.5508 7.34408 17.8503 8.09815 17.8503C8.85221 17.8503 9.57539 17.5508 10.1086 17.0175C10.6418 16.4843 10.9414 15.7612 10.9414 15.0071C10.9414 14.253 10.6418 13.5299 10.1086 12.9966C9.57539 12.4634 8.85221 12.1639 8.09815 12.1639Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.08443 7.6687C7.42597 7.59664 8.7704 7.59664 10.1119 7.6687C10.8451 7.70813 11.4994 8.11224 11.88 8.72258L12.5025 9.72111C12.6845 10.0115 12.9915 10.2086 13.3441 10.2594C13.6367 10.3004 13.9279 10.3451 14.2175 10.3936C15.3032 10.5748 16.0592 11.5256 16.0592 12.5992V18.9877C16.0592 19.5909 15.8195 20.1695 15.393 20.5961C14.9664 21.0226 14.3878 21.2623 13.7846 21.2623H2.41177C1.80852 21.2623 1.22998 21.0226 0.803412 20.5961C0.376848 20.1695 0.137207 19.5909 0.137207 18.9877V12.5992C0.137207 11.5256 0.893121 10.5748 1.97885 10.3936C2.26847 10.3451 2.55962 10.3004 2.85152 10.2587C3.02258 10.2358 3.18655 10.1757 3.33194 10.0827C3.47733 9.98973 3.6006 9.86605 3.69311 9.72036L4.31634 8.72334C4.50475 8.41859 4.76379 8.16371 5.07156 7.98026C5.37932 7.79682 5.72675 7.68945 6.08443 7.6687ZM4.1177 15.0072C4.1177 13.9515 4.53707 12.9391 5.28355 12.1926C6.03004 11.4461 7.04249 11.0267 8.09818 11.0267C9.15387 11.0267 10.1663 11.4461 10.9128 12.1926C11.6593 12.9391 12.0787 13.9515 12.0787 15.0072C12.0787 16.0629 11.6593 17.0753 10.9128 17.8218C10.1663 18.5683 9.15387 18.9877 8.09818 18.9877C7.04249 18.9877 6.03004 18.5683 5.28355 17.8218C4.53707 17.0753 4.1177 16.0629 4.1177 15.0072ZM13.216 13.8699C13.3668 13.8699 13.5114 13.81 13.618 13.7034C13.7247 13.5967 13.7846 13.4521 13.7846 13.3013C13.7846 13.1505 13.7247 13.0058 13.618 12.8992C13.5114 12.7925 13.3668 12.7326 13.216 12.7326C13.0651 12.7326 12.9205 12.7925 12.8139 12.8992C12.7072 13.0058 12.6473 13.1505 12.6473 13.3013C12.6473 13.4521 12.7072 13.5967 12.8139 13.7034C12.9205 13.81 13.0651 13.8699 13.216 13.8699Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.2292 0.980713C20.38 0.980713 20.5246 1.04062 20.6313 1.14726C20.7379 1.2539 20.7978 1.39854 20.7978 1.54935V5.52984H24.7783C24.9291 5.52984 25.0738 5.58975 25.1804 5.69639C25.287 5.80303 25.347 5.94767 25.347 6.09848C25.347 6.2493 25.287 6.39393 25.1804 6.50057C25.0738 6.60721 24.9291 6.66712 24.7783 6.66712H20.7978V10.6476C20.7978 10.7984 20.7379 10.9431 20.6313 11.0497C20.5246 11.1563 20.38 11.2163 20.2292 11.2163C20.0784 11.2163 19.9337 11.1563 19.8271 11.0497C19.7205 10.9431 19.6605 10.7984 19.6605 10.6476V6.66712H15.6801C15.5292 6.66712 15.3846 6.60721 15.278 6.50057C15.1713 6.39393 15.1114 6.2493 15.1114 6.09848C15.1114 5.94767 15.1713 5.80303 15.278 5.69639C15.3846 5.58975 15.5292 5.52984 15.6801 5.52984H19.6605V1.54935C19.6605 1.39854 19.7205 1.2539 19.8271 1.14726C19.9337 1.04062 20.0784 0.980713 20.2292 0.980713Z"
        fill="white"
      />
    </svg>
  );
};

export const LockIcon: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M16.5 10.5V6.75a4.5 4.5 0 10-9 0v3.75m-.75 11.25h10.5a2.25 2.25 0 002.25-2.25v-6.75a2.25 2.25 0 00-2.25-2.25H6.75a2.25 2.25 0 00-2.25 2.25v6.75a2.25 2.25 0 002.25 2.25z"
      />
    </svg>
  );
};

export const CogIcon: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M10.343 3.94c.09-.542.56-.94 1.11-.94h1.093c.55 0 1.02.398 1.11.94l.149.894c.07.424.384.764.78.93.398.164.855.142 1.205-.108l.737-.527a1.125 1.125 0 011.45.12l.773.774c.39.389.44 1.002.12 1.45l-.527.737c-.25.35-.272.806-.107 1.204.165.397.505.71.93.78l.893.15c.543.09.94.56.94 1.109v1.094c0 .55-.397 1.02-.94 1.11l-.893.149c-.425.07-.765.383-.93.78-.165.398-.143.854.107 1.204l.527.738c.32.447.269 1.06-.12 1.45l-.774.773a1.125 1.125 0 01-1.449.12l-.738-.527c-.35-.25-.806-.272-1.203-.107-.397.165-.71.505-.781.929l-.149.894c-.09.542-.56.94-1.11.94h-1.094c-.55 0-1.019-.398-1.11-.94l-.148-.894c-.071-.424-.384-.764-.781-.93-.398-.164-.854-.142-1.204.108l-.738.527c-.447.32-1.06.269-1.45-.12l-.773-.774a1.125 1.125 0 01-.12-1.45l.527-.737c.25-.35.273-.806.108-1.204-.165-.397-.505-.71-.93-.78l-.894-.15c-.542-.09-.94-.56-.94-1.109v-1.094c0-.55.398-1.02.94-1.11l.894-.149c.424-.07.765-.383.93-.78.165-.398.143-.854-.107-1.204l-.527-.738a1.125 1.125 0 01.12-1.45l.773-.773a1.125 1.125 0 011.45-.12l.737.527c.35.25.807.272 1.204.107.397-.165.71-.505.78-.929l.15-.894z"
      />
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"
      />
    </svg>
  );
};

export const OutlineFunnelIcon: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 3c2.755 0 5.455.232 8.083.678.533.09.917.556.917 1.096v1.044a2.25 2.25 0 01-.659 1.591l-5.432 5.432a2.25 2.25 0 00-.659 1.591v2.927a2.25 2.25 0 01-1.244 2.013L9.75 21v-6.568a2.25 2.25 0 00-.659-1.591L3.659 7.409A2.25 2.25 0 013 5.818V4.774c0-.54.384-1.006.917-1.096A48.32 48.32 0 0112 3z"
      />
    </svg>
  );
};

export const EyeIcon: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      <path d="M12 15a3 3 0 100-6 3 3 0 000 6z" />
      <path
        fillRule="evenodd"
        d="M1.323 11.447C2.811 6.976 7.028 3.75 12.001 3.75c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113-1.487 4.471-5.705 7.697-10.677 7.697-4.97 0-9.186-3.223-10.675-7.69a1.762 1.762 0 010-1.113zM17.25 12a5.25 5.25 0 11-10.5 0 5.25 5.25 0 0110.5 0z"
        clipRule="evenodd"
      />
    </svg>
  );
};

export const EyeSlash: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 24 24"
      fill="currentColor"
      className={className}
    >
      <path d="M3.53 2.47a.75.75 0 00-1.06 1.06l18 18a.75.75 0 101.06-1.06l-18-18zM22.676 12.553a11.249 11.249 0 01-2.631 4.31l-3.099-3.099a5.25 5.25 0 00-6.71-6.71L7.759 4.577a11.217 11.217 0 014.242-.827c4.97 0 9.185 3.223 10.675 7.69.12.362.12.752 0 1.113z" />
      <path d="M15.75 12c0 .18-.013.357-.037.53l-4.244-4.243A3.75 3.75 0 0115.75 12zM12.53 15.713l-4.243-4.244a3.75 3.75 0 004.243 4.243z" />
      <path d="M6.75 12c0-.619.107-1.213.304-1.764l-3.1-3.1a11.25 11.25 0 00-2.63 4.31c-.12.362-.12.752 0 1.114 1.489 4.467 5.704 7.69 10.675 7.69 1.5 0 2.933-.294 4.242-.827l-2.477-2.477A5.25 5.25 0 016.75 12z" />
    </svg>
  );
};

export const GoToExtentIcon: ComponentType<{
  className?: string;
}> = ({ className = "w-5 h-5" }) => {
  return (
    <svg
      viewBox="0 0 24 24"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      stroke="none"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20 1.33333H4C2.52724 1.33333 1.33333 2.52724 1.33333 4V20C1.33333 21.4728 2.52724 22.6667 4 22.6667H20C21.4728 22.6667 22.6667 21.4728 22.6667 20V4C22.6667 2.52724 21.4728 1.33333 20 1.33333ZM4 0C1.79086 0 0 1.79086 0 4V20C0 22.2091 1.79086 24 4 24H20C22.2091 24 24 22.2091 24 20V4C24 1.79086 22.2091 0 20 0H4Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3108 0C8.69074 0 7.37744 1.3133 7.37744 2.93333V12.7111C7.37744 14.3311 8.69074 15.6444 10.3108 15.6444H21.0663C22.6864 15.6444 23.9997 14.3311 23.9997 12.7111V4.88889C23.9997 2.18883 21.8108 0 19.1108 0H10.3108ZM12.6062 12.0004C12.4454 12.0004 12.2912 11.9365 12.1774 11.8229C12.0638 11.7092 12 11.5549 12 11.3942C12 11.2334 12.0638 11.0792 12.1774 10.9654L18.0183 5.12457H13.4152C13.2543 5.12457 13.0999 5.06065 12.9862 4.94686C12.8724 4.83308 12.8084 4.67875 12.8084 4.51784C12.8084 4.35692 12.8724 4.2026 12.9862 4.08881C13.0999 3.97503 13.2543 3.9111 13.4152 3.9111H19.4825C19.6434 3.9111 19.7978 3.97503 19.9115 4.08881C20.0253 4.2026 20.0893 4.35692 20.0893 4.51784V10.5852C20.0893 10.7461 20.0253 10.9004 19.9115 11.0142C19.7978 11.128 19.6434 11.1919 19.4825 11.1919C19.3216 11.1919 19.1673 11.128 19.0535 11.0142C18.9397 10.9004 18.8758 10.7461 18.8758 10.5852V5.98209L13.035 11.8229C12.9212 11.9365 12.767 12.0004 12.6062 12.0004Z"
      />
    </svg>
  );
};

export const CursorArrowRipple: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6"
}) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="currentColor"
      className={className}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M15.042 21.672L13.684 16.6m0 0l-2.51 2.225.569-9.47 5.227 7.917-3.286-.672zm-7.518-.267A8.25 8.25 0 1120.25 10.5M8.288 14.212A5.25 5.25 0 1117.25 10.5"
      />
    </svg>
  );
};

export const CompassIcon: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6 fill-black"
}) => {
  return (
    <svg
      className={className}
      viewBox="0 0 300 303"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99999 1.99997L184 91C194 96 208 113 217 130L293 281C302 297 301 306 291 302L115 212C106 208 91 190 83 174L5.99999 23C-2.00001 5.99997 -1.00001 -3.00003 7.99999 1.99997ZM126 179L177 128C181 134 185 140 187 144L234 236L130 183C129 182 127 181 126 179Z"
      />
    </svg>
  );
};

export const MeasurementIcon: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6 stroke-black"
}) => {
  return (
    <svg
      className={className}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.62647 14.7868H2.68606C2.63083 14.7868 2.58606 14.742 2.58606 14.6868V9.44138V9.3402C2.58606 9.28498 2.63083 9.2402 2.68606 9.2402H17.229C17.2843 9.2402 17.329 9.28498 17.329 9.3402V14.6868C17.329 14.742 17.2843 14.7868 17.229 14.7868H15.2178M4.62647 14.7868V12.344M4.62647 14.7868H6.6864M8.88418 14.7868V12.344M8.88418 14.7868H6.6864M8.88418 14.7868H10.9954M13.1066 14.7868V12.1979M13.1066 14.7868H10.9954M13.1066 14.7868H15.2178M6.6864 14.7868V11.0153M10.9954 14.7868V11.0153M15.2178 14.7868V11.0153"
        strokeLinecap="round"
      />
      <path d="M4.74076 5.36324V4.34594C4.74076 4.29071 4.69599 4.24594 4.64076 4.24594H2.69778C2.64255 4.24594 2.59778 4.29071 2.59778 4.34594V6.34392C2.59778 6.39915 2.64255 6.44392 2.69778 6.44392H4.64076C4.69599 6.44392 4.74076 6.39915 4.74076 6.34392V5.36324ZM4.74076 5.36324H15.0797M15.0797 5.36324V4.3132C15.0797 4.25797 15.1244 4.2132 15.1797 4.2132H17.314C17.3692 4.2132 17.414 4.25797 17.414 4.3132V6.34868C17.414 6.40391 17.3692 6.44868 17.314 6.44868H15.1797C15.1244 6.44868 15.0797 6.40391 15.0797 6.34868V5.36324Z" />
    </svg>
  );
};

export const SliceIcon: ComponentType<{ className?: string }> = ({
  className = "w-6 h-6 stroke-black"
}) => {
  return (
    <svg
      className={className}
      width="20"
      height="19"
      viewBox="0 0 20 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_975_43)">
        <path
          d="M10.0367 15.4589H8.88535C8.82284 15.4589 8.77217 15.5095 8.77217 15.572V17.771C8.77217 17.8335 8.82284 17.8842 8.88535 17.8842H11.1466C11.2091 17.8842 11.2597 17.8335 11.2597 17.771V15.572C11.2597 15.5095 11.2091 15.4589 11.1466 15.4589H10.0367ZM10.0367 15.4589V3.75774M10.0367 3.75774H8.84828C8.78578 3.75774 8.73511 3.70707 8.73511 3.64457L8.73511 1.22904C8.73511 1.16653 8.78578 1.11586 8.84828 1.11586H11.1519C11.2145 1.11586 11.2651 1.16653 11.2651 1.22904V3.64457C11.2651 3.70707 11.2145 3.75774 11.1519 3.75774H10.0367Z"
          strokeWidth="1.13176"
        />
        <path
          d="M10.9642 5.79025H15.8113C15.8614 5.79025 15.9021 5.83091 15.9021 5.88106V13.5529C15.9021 13.6031 15.8614 13.6438 15.8113 13.6438H10.9642"
          strokeWidth="1.13176"
          strokeLinecap="round"
        />
        <path
          d="M9.08289 13.6438H4.18883C4.13868 13.6438 4.09802 13.6031 4.09802 13.5529V5.88106C4.09802 5.83091 4.13727 5.79025 4.18742 5.79025C4.70292 5.79025 7.33357 5.79025 9.08289 5.79025"
          strokeWidth="1.13176"
          strokeLinecap="round"
          strokeDasharray="1.13 1.7"
        />
      </g>
      <defs>
        <clipPath id="clip0_975_43">
          <rect width="20" height="19" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
